<template>
  <div>
    <div class="text-center my-3">
    RESULT
   <div>
        <div class="result-heading flex p-2 justify-between" v-on:click="toggle()">
			<h4 class="result-name py-2">Objects Detected</h4>
			<button class="btn btn-transparent">
				<caret-down-icon v-if="!isCollapsed" v-bind:alt="'Open '" v-bind:title="'Open '"></caret-down-icon>
				<caret-up-icon v-else v-bind:alt="'Close '" v-bind:title="'Close '"></caret-up-icon>
			</button>
		</div>
        <div class="relative text-left p-2" v-if="isCollapsed">
            <div class=" my-2">
              <!-- <h5>Objects Detected</h5> -->
              <h6 class="my-3" v-for="(value, name) in objects" :key="name">{{ name }}: {{ value }}</h6>
            </div>
        </div>
  </div>

    <result-drop-down-section :title="'Request'"
    v-bind:receivedResult="requestMade"/>

    <result-drop-down-section :title="'Response JSON'"
    v-bind:receivedResult="result"/>
    
    </div>
</div>
</template>

<script>
import ResultDropDownSection from '../ResultDropDownSection.vue';
import CaretDownIcon from '../../../icons/CaretDownIcon';
import CaretUpIcon from '../../../icons/CaretUpIcon.vue';

export default {
  components: { 
    ResultDropDownSection,
    CaretDownIcon,
    CaretUpIcon
    },
    data() {
      return {
        isCollapsed: Boolean,
        objects: {}
      }
    },
    methods: {
      toggle() {
          this.isCollapsed = !this.isCollapsed;
        }
    },

    watch: {
       
         receivedObjects: {
             immediate:true,
             handler(newVal) {
            var count = {};
            this.objects.length = 0;
            newVal.forEach(function(para) {
                count[para] = (count[para] || 0) + 1;
                });
                this.objects = count;
                return count;
        }}
    },
    

    props: {
      result:Array,
      isLoading:Boolean,
      requestMade: Array,
      objectCount:Number,
      receivedObjects: Array
    }

}
</script>

<style>

</style>